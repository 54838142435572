<template>
	<div class="setting-wrapper p-2 pt-0 w-100">
		<!-- <v-col cols="12" class="border-bottom">
			<Breadcrumbs v-on:right-bar="right_bar = true"></Breadcrumbs>
		</v-col> -->
		<!--begin::Setting-->
		<div class="plan-details border-bottom w-100">
			<div class="v3_current-plan w-100">
				<v-flex>
					<div class="company-name h3">Business Thrust Pte. Ltd</div>
					<span>Current Plan : </span>
					<span class="plan-name">Enterprise</span>
					<span class="trail-info">(Trial)</span>
					<v-alert dense class="p-1 mb-0 ml-2 d-inline-block" outlined type="error">
						<span class="font-x-small"
							>Your account will get deactivated after the trial period.To continue using Freshservice,
							please choose a plan below.</span
						>
					</v-alert>
				</v-flex>
				<div class="border-line"></div>
			</div>
			<div class="account-summary">
				<div class="d-flex justify-content-between">
					<h5>19 Days</h5>
					<v-icon>mdi-clock-time-three-outline</v-icon>
				</div>
				<p class="red--text">Remaining trial period</p>
			</div>
			<div class="account-summary">
				<div class="d-flex justify-content-between">
					<h5>Active Since</h5>
					<v-icon>mdi-calendar</v-icon>
				</div>
				<p>Wed, 2 Mar, 2022</p>
			</div>
			<div class="account-summary">
				<div class="d-flex justify-content-between">
					<h5>Assets</h5>
					<v-icon>mdi-star-box-outline</v-icon>
				</div>
				<p>Free 100</p>
			</div>
			<div class="account-summary">
				<div class="d-flex justify-content-between">
					<h5>Data Center</h5>
					<v-icon>mdi-database</v-icon>
				</div>
				<p>Singapore</p>
			</div>
			<div class="account-summary">
				<div class="d-flex justify-content-between">
					<h5>Version</h5>
					<v-icon>mdi-information-outline</v-icon>
				</div>
				<p>2022.R02.10</p>
			</div>
		</div>
		<v-layout class="border-bottom">
			<v-tabs v-model="tab">
				<v-tab href="#contact-detail">Contact Details</v-tab>
				<v-tab href="#current-plan">Current Plan</v-tab>
				<v-tab href="#billing-detail">Billing Details</v-tab>
				<v-tab href="#api_credentials">API Credentials</v-tab>
			</v-tabs>
			<v-spacer></v-spacer>
			<template v-if="tab == 'contact-detail'">
				<v-btn depressed tile color="white darken-4" class="mr-2"> Cancel </v-btn>
				<v-btn class="white--text" depressed tile color="blue darken-4"> Update </v-btn>
			</template>
		</v-layout>
		<v-col cols="12" class="py-0 px-0 border-left">
			<v-tabs-items v-model="tab" class="setting-tab-item">
				<v-tab-item value="contact-detail">
					<v-layout class="accout-settings flex-wrap">
						<div class="account-sidebar">
							<div class="contact-details-tab p-5">
								<h3 class="title">Primary contact details</h3>
								<div class="field-info">
									(Used for all account-related communications from Freshservice)
								</div>
								<div class="form-element">
									<div class="d-flex mb-8">
										<label for="fname" class="input-label">First Name</label>
										<div class="input-field">
											<TextInput
												dense
												id="fname"
												ref="fname"
												hide-details
												type="text"
												v-model="firstName"
												placeholder="First Name"
												class="mt-0"
											></TextInput>
										</div>
									</div>
									<div class="d-flex mb-8">
										<label for="lname" class="input-label">Last Name</label>
										<div class="input-field">
											<TextInput
												dense
												id="lname"
												ref="lname"
												hide-details
												type="text"
												v-model="lastName"
												placeholder="Last Name"
												class="mt-0"
											></TextInput>
										</div>
									</div>
									<div class="d-flex mb-8">
										<label for="email" class="input-label">Email</label>
										<div class="input-field">
											<TextInput
												dense
												id="email"
												ref="email"
												hide-details
												type="text"
												v-model="email"
												placeholder="First Name"
												class="mt-0"
											></TextInput>
										</div>
									</div>
									<div class="d-flex mb-8">
										<label for="phone" class="input-label">Phone</label>
										<div class="input-field">
											<TextInput
												dense
												id="phone"
												ref="phone"
												hide-details
												type="text"
												v-model="phoneNumber"
												placeholder="Phone Number"
												class="mt-0"
											></TextInput>
										</div>
									</div>
									<h3 class="title">Send invoice to</h3>
									<div class="d-flex mb-8">
										<label for="recieverEmail" class="input-label">Email</label>
										<div class="input-field">
											<v-autocomplete
												dense
												id="recieverEmail"
												ref="recieverEmail"
												hide-details
												chips
												outlined
												small-chips
												v-model="recieverEmail"
												placeholder="Email"
												:items="recieverEmails"
												multiple
												class="cm-auto-complete"
											>
												<template v-slot:selection="data">
													<v-chip
														x-small
														v-bind="data.attrs"
														:input-value="data.selected"
														close
														@click="data.select"
														@click:close="remove(data.item)"
													>
														{{ data.item }}
													</v-chip>
												</template>
											</v-autocomplete>
										</div>
									</div>
								</div>
							</div>
						</div>
					</v-layout>
				</v-tab-item>
				<v-tab-item value="current-plan">
					<div class="setting-tab-content-fix">
						<div class="plan-setting p-5">
							<h3 class="title">Choose Plan</h3>
							<v-row class="plan-card-wrapper">
								<v-col md="4" sm="6">
									<div class="card-wrapper">
										<div class="p-3 plan-border">
											<h3 class="h3 mb-3"><span class="doller">$</span> 15</h3>
											<p class="">
												<strong>Starter</strong>
											</p>
											<div class="border-line"></div>
											<v-btn
												class="white--text mt-5"
												depressed
												tile
												color="blue darken-4"
												@click.stop="drawer = !drawer"
											>
												Choose Plan
											</v-btn>
										</div>
										<div class="plan-desc py-3">
											<v-list dense disabled>
												<v-list-item-group color="primary" v-for="(item, index) in starterPlan" :key="index">
													<v-list-item>
														<v-list-item-icon>
															<v-icon class="success--text">{{ item.icon }}</v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ item.name }}</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
									</div>
								</v-col>
								<v-col md="4" sm="6">
									<div class="card-wrapper">
										<div class="p-3 plan-border">
											<h3 class="h3 mb-3"><span class="doller">$</span> 75</h3>
											<p class=""><strong>Pro</strong></p>
											<div class="border-line"></div>
											<v-btn class="mt-5 white--text" depressed tile color="blue darken-4">
												Choose Plan
											</v-btn>
										</div>
										<div class="plan-desc py-3">
											<v-list dense disabled>
												<v-list-item-group color="primary" v-for="(item, index) in starterPlan" :key="index">
													<v-list-item>
														<v-list-item-icon>
															<v-icon class="success--text">{{ item.icon }}</v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ item.name }}</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
									</div>
								</v-col>
								<v-col md="4" sm="6">
									<div class="card-wrapper active">
										<div class="p-3 plan-border">
											<h3 class="h3 mb-3">
												<span class="doller">$</span>
												95
											</h3>
											<p class="">
												<strong>Enterprise</strong>
											</p>
											<div class="border-line"></div>
											<v-btn class="mt-5 white--text" depressed tile color="blue darken-4">
												View Details
											</v-btn>
										</div>
										<div class="plan-desc py-3">
											<v-list dense disabled>
												<v-list-item-group color="primary" v-for="(item, index) in starterPlan" :key="index">
													<v-list-item>
														<v-list-item-icon>
															<v-icon class="success--text">{{ item.icon }}</v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ item.name }}</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
									</div>
								</v-col>
							</v-row>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="billing-detail">
					<div class="setting-tab-content-fix">
						<div class="p-5 billing-setting">
							<h3 class="title">Billing and payment history</h3>
							<v-row>
								<v-col cols="12">
									<table class="border w-100">
										<thead class="border-bottom">
											<th>Date</th>
											<th>Invoice or credit number</th>
											<th>Payment Method</th>
											<th>Amount</th>
											<th>Action</th>
										</thead>
										<tbody>
											<tr class="border-bottom" v-for="(item, index) in billingDetails" :key="index">
												<td>{{ item.date }}</td>
												<td>{{ item.invoice }}</td>
												<td>
													{{ item.paymentMethod }}
												</td>
												<td>{{ item.amount }}</td>
												<td>
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon class="cursor-pointer" style="color: #f74c4c" v-bind="attrs" v-on="on"
																>mdi-file-pdf</v-icon
															>
														</template>
														<span>Export to PDF</span>
													</v-tooltip>
												</td>
											</tr>
										</tbody>
									</table>
								</v-col>
							</v-row>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="api_credentials">
					<div class="setting-tab-content-fix">
						<div class="p-5 billing-setting">
							<v-row>
								<v-col cols="12">
									<div class="form-element">
										<div class="d-flex mb-8">
											<label for="api_credential" class="input-label">API Key</label>
											<div class="input-field">
												<v-text-field
													id="api_credential"
													hide-details
													outlined
													v-model="api_credential"
													placeholder="Key"
													append-icon="mdi-content-copy"
													@click:append="copyKey()"
												>
												</v-text-field>
											</div>
										</div>
										<div class="d-flex mb-8">
											<label for="api_endpoint" class="input-label">API Endpoint</label>
											<div class="input-field">
												<TextInput
													dense
													id="api_endpoint"
													hide-details
													type="text"
													v-model="api_endpoint"
													placeholder="Endpoint"
													class="mt-0"
												></TextInput>
											</div>
										</div>
									</div>
								</v-col>
							</v-row>
						</div>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
		<!--end::Setting-->
		<v-navigation-drawer
			v-model="drawer"
			fixed
			right
			temporary
			width="560px"
			class="setting-plan-popup"
		>
			<div class="">
				<div class="p-5">
					<div class="h3 py-3">Are you sure you want to downgrade?</div>
					<v-alert dense outlined type="warning">
						<span class="font-x-small">Your plan will downgrade from Enterprise to Starter.</span>
					</v-alert>
					<div class="warning-wrapper d-flex align-items-center">
						<span class="warning-label">Important</span>
						&nbsp;&nbsp;<span class="warning-text"
							>You will permanently lose the features listed <b>(This action cannot be undone)</b></span
						>
					</div>
					<v-flex class="d-flex mt-5">
						<div class="m-auto"><strong>Plan Features</strong></div>
						<v-spacer></v-spacer>
						<v-btn class="white--text" dense tile color="blue darken-4"> Downgrade </v-btn>
					</v-flex>
					<div class="plan-desc py-3">
						<v-list dense disabled>
							<v-list-item-group color="primary" v-for="(item, index) in starterPlan" :key="index">
								<v-list-item>
									<v-list-item-icon>
										<v-icon class="success--text">{{ item.icon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ item.name }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</div>
					<div class="data-retention">
						<span class="ast">*</span>&nbsp;Data will be retained for these features for a period of 30
						days only
					</div>
					<hr />
					<div class="see-full-list">
						To see the full list of plans and features, please visit
						<a target="_blank" href="#">Plans and Pricing</a>. <br />Still not sure?
						<a href="#">Let us help you decide</a>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "dashboard",
	data() {
		return {
			drawer: null,
			items: [
				{ title: "Home", icon: "mdi-view-dashboard" },
				{ title: "About", icon: "mdi-forum" },
			],
			tab: "contact-detail",
			detailsTab: null,
			firstName: null,
			lastName: null,
			email: null,
			phoneNumber: null,
			recieverEmail: [],
			recieverEmails: ["********", "********", "********"],
			starterPlan: [
				{
					name: "Incident Management",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Self-service Portal",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Knowledge Base",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Incident Management",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Self-service Portal",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Knowledge Base",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Incident Management",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Self-service Portal",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
				{
					name: "Knowledge Base",
					icon: "mdi-checkbox-multiple-marked-circle",
				},
			],
			billingDetails: [
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
				{
					date: "20/02/2022",
					invoice: "54652423542123",
					paymentMethod: "Master * 24",
					amount: "35.42",
					action: "Export to PDF",
				},
			],
		};
	},
	components: {
		TextInput,
	},
	methods: {
		remove(item) {
			const index = this.recieverEmail.indexOf(item);
			if (index >= 0) this.recieverEmail.splice(index, 1);
		},
	},
};
</script>
<style scoped>
.caption {
	font-size: 16px;
	width: 100%;
}
.cur_plan {
	color: #141c30;
	font-size: 14px;
	text-transform: capitalize;
	display: inline-block;
	font-weight: normal;
	padding-right: 5px;
}
</style>
